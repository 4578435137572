import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import moment from "moment";
import cx from "classnames";
import Calendar from "./Calendar/Calendar";

import { getAccessToken } from "../../helpers/sessionStorageHelper";
import logo from "../../assets/logo.png";

import {
  fetchBookNowDetail,
  getBookNowData,
  makeSlotAvailable,
  newBooking,
  verifyPayment,
} from "../../dux/bookNow";
import { Button } from "@mui/material";
import {
  getTotalCost,
  isSlotAvailable,
  mapStartTimeToIndex,
  mapTurf,
} from "../../helpers/bookNowHelper";
import styles from "./BookNow.module.css";
import { getLoginFlag } from "../../dux/login";
import { showPrompt } from "../../dux/prompt";
import { promptCategoryType } from "../../helpers/constants";

import useRazorpay from "react-razorpay";
import { getUser } from "../../helpers/sessionStorageHelper";
import { getCurrentTimeString } from "../../helpers/dateHelper";
import {
  apiCallComplete,
  isFetchBookNowPending,
  isNewBookingPending,
} from "../../dux/apiStatus";
import Loader from "../shared/UI/Loader/Loader";
import LoaderForButton from "../shared/UI/LoaderForButton";
import { getNewBookingFlag, resetBookingFlag } from "../../dux/ui/uiBookNow";
import { useNavigate } from "react-router-dom";
import { setTab } from "../../dux/myBookingTab";
import { getBookingTimeFrame } from "../../helpers/myBookingsHelper";

const BookNow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [duration, setDuration] = useState(1);
  const [date, setDate] = useState(dayjs());
  const [allSlots, setAllSlots] = useState([]);
  const [selectedSport, setSelectedSport] = useState("Football");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedTurf, setSelectedTurf] = useState("");
  const login = useSelector(getLoginFlag);
  const fetchingBookNowDetail = useSelector(isFetchBookNowPending);
  const newBookingInProgress = useSelector(isNewBookingPending);
  const newBookingHappened = useSelector(getNewBookingFlag);
  const { emailAddress, mobileNumber, firstName, lastName } = getUser();

  // Code added by Anshul to handle different partial payments amount for Football/Box Cricket
  let amt = 505;
  let btn_partial_txt = "PAY PARTIAL (500/-)";

  if (selectedSport == "Football" || selectedSport == "Box Cricket") {
    if (duration == 2) {
      amt = 1010;
      btn_partial_txt = "PAY PARTIAL (1000/-)";
    } else if (duration == 3) {
      amt = 1515;
      btn_partial_txt = "PAY PARTIAL (1500/-)";
    } else if (duration == 4) {
      amt = 2020;
      btn_partial_txt = "PAY PARTIAL (2000/-)";
    }
  }

  const timeFormat = (timeString) => {
    const newTime = timeString.split(" - ");

    const timeString12hr = new Date(
      "1970-01-01T" + newTime[0] + "Z"
    ).toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });

    const timeString12hr_ = new Date(
      "1970-01-01T" + newTime[1] + "Z"
    ).toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });

    return `${timeString12hr} - ${timeString12hr_}`;
  };

  const disableBtn = () =>
    !(selectedSport && selectedSlot && duration && date && selectedTurf);

  const {
    bookingAllowed,
    slotsAvailability: { turfA, turfB, turfC },
    slots,
    newBooking: newBookingData,
  } = useSelector(getBookNowData);

  const dateChangeHandler = (value) => {
    dispatch(
      fetchBookNowDetail({
        date: value.format("DD/MM/YYYY"),
        sport: selectedSport,
      })
    );
    setDate(value);
    setSelectedSlot("");
    setSelectedTurf("");
  };

  useEffect(() => {
    setAllSlots(slots.turfA);
  }, [slots]);

  useEffect(() => {
    if (newBookingHappened) {
      dispatch(resetBookingFlag());
      dispatch(setTab({ tab: getBookingTimeFrame(date.format("DD/MM/YYYY")) }));
      navigate("/my-account/bookings");
    }
  }, [newBookingHappened]);

  useEffect(() => {
    if (newBookingData.order_id !== "") {
      paymentHandler({
        order_id: newBookingData.order_id,
        amount: newBookingData.amount,
        paidAmount: newBookingData.paidAmount,
        totalAmount: newBookingData.totalAmount,
      });
    }
  }, [newBookingData]);

  useEffect(() => {
    dispatch(
      fetchBookNowDetail({
        date: date.format("DD/MM/YYYY"),
        sport: selectedSport,
      })
    );
    return () => {
      dispatch(apiCallComplete({ api: "newBooking" }));
    };
  }, []);

  useEffect(() => {
    setSelectedTurf("");
    setSelectedSlot("");
    dispatch(
      fetchBookNowDetail({
        date: date.format("DD/MM/YYYY"),
        sport: selectedSport,
      })
    );
  }, [selectedSport]);

  const getTimeSlot = (time) =>
    `${time} - ${moment(time, ["h:m a", "H:m"])
      .add(1, "hour")
      .format("HH:mm")}`;

  const getFinalSlot = () =>
    selectedSlot
      ? `${selectedSlot} - ${moment(selectedSlot, ["h:m a", "H:m"])
          .add(duration, "hour")
          .format("HH:mm")}`
      : "Select Slot";

  const slotClickHandler = (selTime) => {
    setSelectedSlot(selTime);
    setSelectedTurf("");
    setAllSlots((prev) =>
      prev.map((i) =>
        i.time === selTime
          ? { ...i, selected: true }
          : { ...i, selected: false }
      )
    );
  };

  const totalCost = () => {
    return selectedTurf === "" || selectedSlot === ""
      ? ""
      : getTotalCost(slots[selectedTurf], selectedSlot, duration);
  };

  const durationBtnClickHandler = (v) => setDuration((prev) => prev + v);

  const Razorpay = useRazorpay();

  const paymentHandler = async ({ amount, order_id }) => {
    // const handlePayment = async (params) => {
    // const order = await createOrder(params); //  Create order on your backend

    const hostUrl =
      window.location.hostname === "localhost"
        ? "http://localhost:3002"
        : "https://whssportshub.in";

    const r_key =
      window.location.hostname === "localhost"
        ? "rzp_test_yUsq2PHVQpdBvG"
        : "rzp_live_zonoIiPc15csvh";

    const options = {
      key: r_key, // Enter the Key ID generated from the Dashboard
      amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "WHS sports hub",
      description: "Booking slot",
      image: logo,
      // redirect_url: "http://localhost:3000/my-account/bookings",
      order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      // callback_url: `http://localhost:3000/api/v1/booking/verify?access_token=${getAccessToken()}&transactionId=${
      //   newBookingData.transactionId
      // }&sport=${selectedSport}&turfNumber=${
      //   mapTurf[selectedTurf]
      // }&slotDate=${date.format(
      //   "DD/MM/YYYY"
      // )}&slotTime=${selectedSlot}&duration=${duration}&amount=${amount}`,
      redirect: true,
      handler: function (response) {
        const { razorpay_order_id, razorpay_payment_id, razorpay_signature } =
          response;
        dispatch(apiCallComplete({ api: "newBooking" }));
        navigate("/my-account/bookings");
        dispatch(
          verifyPayment({
            razorpay_order_id,
            razorpay_payment_id,
            razorpay_signature,
            transactionId: newBookingData.transactionId,
            sport: selectedSport,
            turfNumber: mapTurf[selectedTurf],
            slotDate: date.format("DD/MM/YYYY"),
            slotTime: selectedSlot,
            duration,
            paidAmount: amount,
            totalAmount: costWithExtraFee() * 100,
          })
        );
      },
      modal: {
        ondismiss: function () {
          dispatch(
            makeSlotAvailable({
              turfNumber: mapTurf[selectedTurf],
              slotDate: date.format("DD/MM/YYYY"),
              slotTime: selectedSlot,
              sport: selectedSport,
            })
          );
          dispatch(apiCallComplete({ api: "newBooking" }));
        },
      },
      prefill: {
        name: `${firstName} ${lastName}`,
        email: emailAddress,
        contact: mobileNumber,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      dispatch(apiCallComplete({ api: "newBooking" }));

      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();
    // };
  };

  const costWithExtraFee = () => Math.ceil((totalCost() * 101) / 100);

  const submitHandler = (partial) => {
    if (login) {
      if (partial == 1) {
        dispatch(
          newBooking({
            sport: selectedSport,
            emailAddress,
            slotDate: date.format("DD/MM/YYYY"),
            slotTime: selectedSlot,
            duration,
            turfNumber: mapTurf[selectedTurf],
            paidAmount: amt,
            totalAmount: costWithExtraFee(),
          })
        );
      } else {
        dispatch(
          newBooking({
            sport: selectedSport,
            emailAddress,
            slotDate: date.format("DD/MM/YYYY"),
            slotTime: selectedSlot,
            duration,
            turfNumber: mapTurf[selectedTurf],
            paidAmount: costWithExtraFee(),
            totalAmount: costWithExtraFee(),
          })
        );
      }
      // dispatch(sendFeedback({ feedback: value }));
    } else {
      dispatch(showPrompt({ category: promptCategoryType.LOGIN }));
    }
  };

  const isAnySlotAvailable = (index) => {
    switch (selectedSport) {
      case "Football":
      case "Box Cricket":
        return turfA[index] || turfB[index] || turfC[index];

      case "Pickle Ball":
        return turfA[index] || turfB[index];

      case "Cricket Net":
        return turfA[index] || turfB[index];

      default:
        break;
    }
    return false;
  };

  const shouldDisableSlotTime = (time) => {
    const currentTime = getCurrentTimeString();
    const index = mapStartTimeToIndex[time];

    if (
      (currentTime > time &&
        date.format("DD/MM/YYYY") === dayjs().format("DD/MM/YYYY")) ||
      !isAnySlotAvailable(index)
    ) {
      return true;
    }

    return false;
  };

  const btnStyle = {
    fontSize: "40px",
    backgroundColor: "rgb(240, 240, 240)",
    padding: 0,
    borderRadius: "50%",
    margin: "15px",
    cursor: "pointer",
    lineHeight: "65px",
  };
  const getLastSlot = () =>
    slots.turfA.length !== 0 &&
    mapStartTimeToIndex[slots.turfA[slots.turfA.length - 1].time] / 2;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.calendarContainer}>
          <div className={styles.sportContainer}>
            <h3 className={styles.heading3}>Select Sports:</h3>
            <div className={styles.sportsCards}>
              <button
                onClick={() => setSelectedSport("Football")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Football",
                })}
              >
                <div className={styles.footballSportCard} />
                <span>Football</span>
              </button>

              <button
                onClick={() => setSelectedSport("Box Cricket")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Box Cricket",
                })}
              >
                <div className={styles.cricketSportCard} />
                <span>Box Cricket</span>
              </button>

              <button
                onClick={() => setSelectedSport("Cricket Net")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Cricket Net",
                })}
                // disabled={true}
              >
                <div className={styles.cricketSportCard} />
                <span>Cricket Nets</span>
              </button>

              <button
                onClick={() => setSelectedSport("Pickle Ball")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Pickle Ball",
                })}
                // disabled={true}
              >
                <div className={styles.iconContainer}>
                  <div className={styles.pickeBallSportCard} />
                  {/* <div className={styles.badmintonSportCard} /> */}
                </div>

                <span>Pickleball</span>
              </button>
            </div>
          </div>
          <h3 className={styles.heading3}>Select Playing Date:</h3>
          <Calendar value={date} setValue={dateChangeHandler} />
          <div className={styles.date}>{date.format("DD MMM, YYYY")}</div>
        </div>
        <div className={styles.turfContainer}>
          {fetchingBookNowDetail && <Loader size="large" />}

          {!fetchingBookNowDetail && bookingAllowed ? (
            <>
              <div>
                <div className={styles.slotContainer}>
                  <h3 className={styles.heading3}>Select Slot:</h3>
                  <div className={styles.slots}>
                    {(allSlots || []).map(
                      ({ selected = false, time, cost }) => (
                        <button
                          key={time}
                          onClick={() => slotClickHandler(time)}
                          className={cx(styles.slot, styles.available, {
                            [styles.selectedTime]: selected,
                          })}
                          disabled={shouldDisableSlotTime(time)}
                        >
                          {timeFormat(getTimeSlot(time))}
                        </button>
                      )
                    )}
                  </div>
                </div>

                <div className={styles.durationContainer}>
                  <h3 className={styles.heading3}>Select Duration:</h3>

                  <div>
                    <Button
                      onClick={() => durationBtnClickHandler(-1)}
                      style={btnStyle}
                      disabled={duration === 1}
                    >
                      <strong>-</strong>
                    </Button>
                    <span>{duration} hour(s)</span>
                    <Button
                      style={btnStyle}
                      onClick={() => durationBtnClickHandler(1)}
                      disabled={
                        duration >=
                        getLastSlot() -
                          mapStartTimeToIndex[selectedSlot] / 2 +
                          1
                      }
                    >
                      <strong>+</strong>
                    </Button>
                  </div>
                </div>
              </div>
              <h3 className={styles.heading3}>Select Turf:</h3>

              <div className={styles.availableTurf}>
                {selectedSport === "Pickle Ball" && (
                  // Code for Pickle Ball Net
                  <>
                    <button
                      onClick={() => setSelectedTurf("turfA")}
                      className={cx(styles.turfCard, {
                        [styles.selectedTurf]: selectedTurf === "turfA",
                      })}
                      disabled={!isSlotAvailable(turfA, selectedSlot, duration)}
                    >
                      Court A
                    </button>

                    <button
                      onClick={() => setSelectedTurf("turfB")}
                      className={cx(styles.turfCard, {
                        [styles.selectedTurf]: selectedTurf === "turfB",
                      })}
                      disabled={!isSlotAvailable(turfB, selectedSlot, duration)}
                    >
                      Court B
                    </button>
                  </>
                )}

                {selectedSport === "Cricket Net" && (
                  // Code for Cricket Net
                  <>
                    <button
                      onClick={() => setSelectedTurf("turfA")}
                      className={cx(styles.turfCard, {
                        [styles.selectedTurf]: selectedTurf === "turfA",
                      })}
                      disabled={!isSlotAvailable(turfA, selectedSlot, duration)}
                    >
                      Net A (3 Players Max)
                    </button>

                    <button
                      onClick={() => setSelectedTurf("turfD")}
                      className={cx(styles.turfCard, {
                        [styles.selectedTurf]: selectedTurf === "turfD",
                      })}
                      disabled={!isSlotAvailable(turfA, selectedSlot, duration)}
                    >
                      Net A (6 Players Max)
                    </button>

                    <button
                      onClick={() => setSelectedTurf("turfB")}
                      className={cx(styles.turfCard, {
                        [styles.selectedTurf]: selectedTurf === "turfB",
                      })}
                      disabled={!isSlotAvailable(turfB, selectedSlot, duration)}
                    >
                      Net B (3 Players Max)
                    </button>

                    <button
                      onClick={() => setSelectedTurf("turfE")}
                      className={cx(styles.turfCard, {
                        [styles.selectedTurf]: selectedTurf === "turfE",
                      })}
                      disabled={!isSlotAvailable(turfB, selectedSlot, duration)}
                    >
                      Net B (6 Players Max)
                    </button>
                  </>
                )}

                {["Football", "Box Cricket"].includes(selectedSport) && (
                  // Code for Box Cricket & Football
                  <>
                    <button
                      onClick={() => setSelectedTurf("turfA")}
                      className={cx(styles.turfCard, {
                        [styles.selectedTurf]: selectedTurf === "turfA",
                      })}
                      disabled={!isSlotAvailable(turfA, selectedSlot, duration)}
                    >
                      5 a side Turf A
                    </button>

                    <button
                      onClick={() => setSelectedTurf("turfB")}
                      className={cx(styles.turfCard, {
                        [styles.selectedTurf]: selectedTurf === "turfB",
                      })}
                      disabled={!isSlotAvailable(turfB, selectedSlot, duration)}
                    >
                      5 a side Turf B
                    </button>

                    <button
                      onClick={() => setSelectedTurf("turfC")}
                      className={cx(styles.turfCard, {
                        [styles.selectedTurf]: selectedTurf === "turfC",
                      })}
                      disabled={!isSlotAvailable(turfC, selectedSlot, duration)}
                    >
                      7 a side Turf C
                    </button>
                  </>
                )}
              </div>

              {totalCost() && (
                <span className={styles.costMobileScreen}>
                  <span>Booking Fee </span>
                  <span>{totalCost()} &#8377;</span>
                  <span>Convenience Fee </span>
                  <span>{Math.ceil((totalCost() * 1) / 100)} &#8377;</span>
                  <strong>Total </strong>
                  <strong>{costWithExtraFee()} &#8377;</strong>
                </span>
              )}
              <div className={styles.finalSlot}>
                {selectedSlot ? timeFormat(getFinalSlot()) : "Select Slot"}
              </div>
            </>
          ) : (
            <span className={styles.msg}>
              Bookings are not allowed for the selected date.
            </span>
          )}
        </div>
      </div>
      <div className={styles.btnContainer}>
        {!login && (
          <button
            className={styles.btn}
            onClick={() => submitHandler(0)}
            disabled={disableBtn() || newBookingInProgress}
          >
            {newBookingInProgress ? <LoaderForButton /> : "LOGIN TO CONTINUE"}
          </button>
        )}
      </div>

      <div className={styles.btnContainer}>
        {login && (
          <button
            className={styles.btn}
            onClick={() => submitHandler(0)}
            disabled={disableBtn() || newBookingInProgress}
          >
            {newBookingInProgress ? <LoaderForButton /> : "PROCEED TO PAY"}
          </button>
        )}
      </div>

      {login && totalCost() > 700 && selectedSport != "Pickle Ball" && (
        <div className={styles.btnContainer}>
          <button
            className={styles.btn}
            onClick={() => submitHandler(1)}
            disabled={disableBtn() || newBookingInProgress}
          >
            {newBookingInProgress ? <LoaderForButton /> : btn_partial_txt}
          </button>
        </div>
      )}
    </>
  );
};

export default BookNow;
