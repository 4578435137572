import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import {
  fetchBookingSetting,
  getBookingSetting,
  resetBookingSetting,
  updateBookingSetting,
} from "../../../dux/bookingSetting";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import { formatDate } from "../../../helpers/dateHelper";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "dayjs/locale/en-in";
import { mapTurfLabels } from "../../../helpers/constants";

const parseTimeStringToDate = (timeString) => {
  if (!timeString) {
    return null; // or you can return another default value, like "00:00"
  }
  const [hours, minutes] = timeString.split(":");
  const today = new Date(); // Create a new Date object with today's date
  today.setHours(Number(hours));
  today.setMinutes(Number(minutes));
  return dayjs(today);
};

const round24HrFormat = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const timeIn24HourFormat = `${formattedHours}:${formattedMinutes}`;
  return timeIn24HourFormat;
};

const turfOptions = ["TURF_A", "TURF_B", "TURF_C"];

const TurfComponent = ({
  config,
  turfName,
  conf,
  rate_type,
  routine,
  sport,
}) => {
  const [rows, setRows] = React.useState(conf);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [rowToDelete, setRowToDelete] = React.useState(null);

  useEffect(() => {
    setRows(conf);
  }, [conf]);

  const handleAddRow = () => {
    setRows([...rows, { startTime: "", endTime: "", cost: "" }]);
  };

  const handleRowChange = (index, key, value, turf) => {
    const updatedRows = [...rows];
    if (key == "cost") {
      if (config[rate_type][routine][turf][index] == undefined) {
        config[rate_type][routine][turf].push({
          [key]: +value,
        });
      }
      config[rate_type][routine][turf][index][key] = +value;
      updatedRows[index][key] = +value;
      setRows(updatedRows);

      return;
    }

    if (config[rate_type][routine][turf][index] == undefined) {
      config[rate_type][routine][turf].push({
        [key]: value,
      });
    }
    config[rate_type][routine][turf][index][key] = value;

    updatedRows[index][key] = value;
    setRows(updatedRows);
  };

  const handleDeleteRow = (index, turf) => {
    setRowToDelete({ index, turf });
    setDeleteDialogOpen(true);
  };

  const confirmDeleteRow = () => {
    const { index, turf } = rowToDelete;
    const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
    let turf_array = [...config[rate_type][routine][turf]];
    turf_array = turf_array.filter((_, idx) => {
      return index !== idx;
    });
    config[rate_type][routine][turf] = turf_array;
    setDeleteDialogOpen(false);
    setRowToDelete(null);
  };

  const cancelDeleteRow = () => {
    setDeleteDialogOpen(false);
    setRowToDelete(null);
  };
  return (
    <div>
      <h2>{mapTurfLabels[sport][turfName]}</h2>
      {rows?.map((row, index) => (
        <div
          key={index}
          style={{ marginBottom: "10px", display: "flex", padding: "0px" }}
        >
          <div style={{ maxWidth: "140px", marginRight: "5px" }}>
            <TimePicker
              label="Start Time"
              value={parseTimeStringToDate(row.startTime)}
              onChange={(time) =>
                handleRowChange(
                  index,
                  "startTime",
                  round24HrFormat(time),
                  turfName
                )
              }
            />
          </div>
          <div style={{ maxWidth: "140px", marginRight: "5px" }}>
            <TimePicker
              label="End Time"
              value={parseTimeStringToDate(row.endTime)}
              onChange={(time) =>
                handleRowChange(
                  index,
                  "endTime",
                  round24HrFormat(time),
                  turfName
                )
              }
            />
          </div>
          <TextField
            label="Cost"
            type="number"
            value={row.cost}
            onChange={(e) =>
              handleRowChange(index, "cost", e.target.value, turfName)
            }
            style={{ maxWidth: "140px" }}
          />

          <IconButton onClick={() => handleDeleteRow(index, turfName)}>
            <DeleteIcon />
          </IconButton>

          <Dialog open={isDeleteDialogOpen} onClose={cancelDeleteRow}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this row?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelDeleteRow} color="primary">
                Cancel
              </Button>
              <Button onClick={confirmDeleteRow} color="primary" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ))}
      <IconButton onClick={handleAddRow}>
        <AddIcon />
      </IconButton>
    </div>
  );
};

const SpecialOfferSetting = ({ sport }) => {
  const dispatch = useDispatch();
  const [isSaveSettingDialogOpen, setSaveSettingDialogOpen] =
    React.useState(false);

  const booking_setting = useSelector(getBookingSetting);

  const [startDate, setStartDate] = React.useState(
    dayjs(
      booking_setting?.bookingSetting?.SPECIAL_OFFER_START_DATE,
      "DD/MM/YYYY"
    ) || null
  );
  const [endDate, setEndDate] = React.useState(
    dayjs(
      booking_setting?.bookingSetting?.SPECIAL_OFFER_LAST_DAY,
      "DD/MM/YYYY"
    ) || null
  );

  const handleSave = () => {
    let payload = JSON.stringify(booking_setting?.bookingSetting);
    payload = JSON.parse(payload);

    payload.SPECIAL_OFFER_START_DATE = formatDate(startDate.format(), 3);
    payload.SPECIAL_OFFER_LAST_DAY = formatDate(endDate.format(), 3);

    dispatch(updateBookingSetting({ json: payload, sport }));
    setSaveSettingDialogOpen(false);
  };

  useEffect(() => {
    dispatch(resetBookingSetting());
    dispatch(fetchBookingSetting({ sport }));
  }, [sport, dispatch]);

  useEffect(() => {
    setStartDate(
      dayjs(
        booking_setting?.bookingSetting?.SPECIAL_OFFER_START_DATE,
        "DD/MM/YYYY"
      )
    );
    setEndDate(
      dayjs(
        booking_setting?.bookingSetting?.SPECIAL_OFFER_LAST_DAY,
        "DD/MM/YYYY"
      )
    );
  }, [booking_setting]);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-in"}>
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            width: "100%",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <h1>Start Date: </h1>
          <div style={{ marginLeft: "10px" }}>
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
            />
          </div>
          <h1 style={{ marginLeft: "10px" }}>End Date: </h1>
          <div style={{ marginLeft: "10px" }}>
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <Button
            variant="outlined"
            color="success"
            onClick={() => setSaveSettingDialogOpen(true)}
          >
            Save
          </Button>
          <div style={{ width: "50%", padding: "10px" }}>
            <h1>Weekday Routine</h1>
            <TurfComponent
              sport={sport}
              config={booking_setting?.bookingSetting}
              turfName="TURF_A"
              conf={
                booking_setting?.bookingSetting?.SPECIAL_OFFER_RATES?.WEEKDAYS
                  ?.TURF_A
              }
              rate_type="SPECIAL_OFFER_RATES"
              routine="WEEKDAYS"
            />

            {["Box Cricket", "Football", "Cricket Net", "Pickle Ball"].includes(
              sport
            ) && (
              <TurfComponent
                sport={sport}
                config={booking_setting?.bookingSetting}
                turfName="TURF_B"
                conf={
                  booking_setting?.bookingSetting?.SPECIAL_OFFER_RATES?.WEEKDAYS
                    ?.TURF_B
                }
                rate_type="SPECIAL_OFFER_RATES"
                routine="WEEKDAYS"
              />
            )}
            {["Box Cricket", "Football"].includes(sport) && (
              <TurfComponent
                sport={sport}
                config={booking_setting?.bookingSetting}
                turfName="TURF_C"
                conf={
                  booking_setting?.bookingSetting?.SPECIAL_OFFER_RATES?.WEEKDAYS
                    ?.TURF_C
                }
                rate_type="SPECIAL_OFFER_RATES"
                routine="WEEKDAYS"
              />
            )}
            {["Cricket Net"].includes(sport) && (
              <>
                <TurfComponent
                  sport={sport}
                  config={booking_setting?.bookingSetting}
                  turfName="TURF_D"
                  conf={
                    booking_setting?.bookingSetting?.SPECIAL_OFFER_RATES
                      ?.WEEKDAYS?.TURF_D
                  }
                  rate_type="SPECIAL_OFFER_RATES"
                  routine="WEEKDAYS"
                />
                <TurfComponent
                  sport={sport}
                  config={booking_setting?.bookingSetting}
                  turfName="TURF_E"
                  conf={
                    booking_setting?.bookingSetting?.SPECIAL_OFFER_RATES
                      ?.WEEKDAYS?.TURF_E
                  }
                  rate_type="SPECIAL_OFFER_RATES"
                  routine="WEEKDAYS"
                />
              </>
            )}
          </div>

          <div style={{ width: "50%", padding: "10px" }}>
            <h1>Sunday Routine</h1>
            <TurfComponent
              sport={sport}
              config={booking_setting?.bookingSetting}
              turfName="TURF_A"
              conf={
                booking_setting?.bookingSetting?.SPECIAL_OFFER_RATES
                  ?.SUNDAY_ROUTINE?.TURF_A
              }
              rate_type="SPECIAL_OFFER_RATES"
              routine="SUNDAY_ROUTINE"
            />

            {["Box Cricket", "Football", "Cricket Net"].includes(sport) && (
              <TurfComponent
                sport={sport}
                config={booking_setting?.bookingSetting}
                turfName="TURF_B"
                conf={
                  booking_setting?.bookingSetting?.SPECIAL_OFFER_RATES
                    ?.SUNDAY_ROUTINE?.TURF_B
                }
                rate_type="SPECIAL_OFFER_RATES"
                routine="SUNDAY_ROUTINE"
              />
            )}
            {["Box Cricket", "Football"].includes(sport) && (
              <TurfComponent
                sport={sport}
                config={booking_setting?.bookingSetting}
                turfName="TURF_C"
                conf={
                  booking_setting?.bookingSetting?.SPECIAL_OFFER_RATES
                    ?.SUNDAY_ROUTINE?.TURF_C
                }
                rate_type="SPECIAL_OFFER_RATES"
                routine="SUNDAY_ROUTINE"
              />
            )}

            {["Cricket Net"].includes(sport) && (
              <>
                <TurfComponent
                  sport={sport}
                  config={booking_setting?.bookingSetting}
                  turfName="TURF_D"
                  conf={
                    booking_setting?.bookingSetting?.SPECIAL_OFFER_RATES
                      ?.SUNDAY_ROUTINE?.TURF_D
                  }
                  rate_type="SPECIAL_OFFER_RATES"
                  routine="SUNDAY_ROUTINE"
                />{" "}
                <TurfComponent
                  sport={sport}
                  config={booking_setting?.bookingSetting}
                  turfName="TURF_E"
                  conf={
                    booking_setting?.bookingSetting?.SPECIAL_OFFER_RATES
                      ?.SUNDAY_ROUTINE?.TURF_E
                  }
                  rate_type="SPECIAL_OFFER_RATES"
                  routine="SUNDAY_ROUTINE"
                />
              </>
            )}
          </div>
        </div>
        <Dialog open={isSaveSettingDialogOpen}>
          <DialogTitle>Confirm Save</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to save the setting ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setSaveSettingDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </div>
  );
};

export default SpecialOfferSetting;
